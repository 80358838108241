<template>
    <v-container>
        <div v-if="authError">
            {{  loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div> 
        <div v-else-if="reportpanel === 'statsSummary'">
            <AdminReportsStats />
        </div>
        <div v-else>
            <AdminTitleBar
                :title="loc('Data Exports')"
                :actions="[]"
            />

            <v-row>
                <v-col>
                    <v-select 
                        :label="loc('Select Data for Export')"
                        :items="selectlist"
                        v-model="exportkey"
                        @change="handleSelectChange"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="exportkey">
                <v-col cols="12">
                    <v-text-field 
                        :label="loc('Filename')"
                        v-model="exportfilename"
                    />
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" :href="exportlink">{{ loc("Export") }}</v-btn>
                </v-col>
            </v-row>
            <div class="trafficdata">
                <v-row class="mt-8">
                    <v-col><h2>{{ loc('Traffic Stats') }}</h2></v-col>
                </v-row>
                <v-row>
                    <v-col>{{ reportpanel }}
                        <p><router-link to="/admin/reports/statsSummary">Stats Summary</router-link></p>
                        <p><a href="https://nodeping.com/reports/statusevents/check/201604021558H3KG6-JEGFBRBK" target="_blank">Hourly Stats</a></p>
                        <p><a href="https://nodeping.com/reports/results/z10i46ag-2cf5-4pny-8je3-prlbe16hunjv/2160?format=csv" target="_blank">Hourly Stats as CSV</a> (up to 90 days)</p>
                    </v-col>
                </v-row>
            </div>
        </div>     

    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
import AdminReportsStats from "./Admin_Reports_Stats.vue";
import {filterId} from "../lib/filter.js";

export default {
    name: "AdminUsers",
    components: { AdminTitleBar, AdminReportsStats },
    data() {
        return {
            exportkey: false,
            exportfilename: '',
            panel: false,
            exportmap: {
                users: {
                    label: "Users",
                    key: "users",
                },
                usersFromPage: {
                    label: "Users from Getting Started With KTP",
                    key: "users",
                    querystring: "?regpath=Getting_"
                },
                enrollment: {
                    label: "Class Enrollment",
                    key: "enrollment"
                },
                courseenrollment: {
                    label: "Course Enrollment",
                    key: "courseenrollment"
                },
                courses: {
                    label: "Courses",
                    key: "courses"
                },
                coursestats: {
                    label: "Course Enrollment Counts",
                    key: "coursestats"
                },
                classes: {
                    label: "Classes",
                    key: "classes"
                },
                surveys: {
                    label: "Surveys",
                    key: "surveys"
                },
                surveyanswers: {
                    label: "Survey Responses (All)",
                    key: "surveyanswers"
                },
                surveyanswers_Lessons: {
                    label: "Survey Responses (Lessons)",
                    key: "surveyanswers",
                    querystring: "?context=Lesson",
                },
                surveyanswers_Contacts: {
                    label: "Survey Responses (Contact Form)",
                    key: "surveyanswers",
                    querystring: "?context=Page&contextid=Contact",
                },    
                surveyanswers_FrenchPages: {
                    label: "Survey Responses (French Pages)",
                    key: "surveyanswers",
                    querystring: "?context=Page&lang=fr",
                },             
                pagestats: {
                    label: "Usage Records",
                    key: "pagestats"
                },
                pagestats_downloads: {
                    label: "Usage - Downloads",
                    key: "pagestats",
                    querystring: "?context=static",
                },   
            }
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize"]),
        selectlist: function(){
            let out = [];
            for(let k in this.exportmap){
                out.push({text: this.exportmap[k].label, value: k })
            }
            return out;
        },
        exportlink: function(){
            let filename = filterId(this.exportfilename);
            let path = (this.exportmap[this.exportkey] && this.exportmap[this.exportkey].key) || this.exportkey;
            let querystring = (this.exportmap[this.exportkey] && this.exportmap[this.exportkey].querystring) || '';
            return "/app_reports/" + path + "/" + filename + querystring;
        },
        reportpanel: function(){
            return this.$route.params.pathMatch
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleSelectChange: function(e){
            console.log("handleSelectChange", e, this.exportkey)
            this.exportfilename = this.exportkey + ".csv";
        }, 
        // handleFileChange: function(e){

        // },
        runexport: function(){
            console.log("Go to ", this.exportlink)
        }
    }
}
</script>